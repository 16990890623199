var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var ReportBug;
(function (ReportBug) {
    function openDialog(appCode, loggedUser, onSuccess, onError, onCancel, title, textAreaPlaceholder, fileUploadLabelText, submitButtonText, cancelButtonText, toastSuccessMessage, toastErrorMessage) {
        if (loggedUser === void 0) { loggedUser = null; }
        if (onSuccess === void 0) { onSuccess = function () { return void 0; }; }
        if (onError === void 0) { onError = function () { return void 0; }; }
        if (onCancel === void 0) { onCancel = function () { return void 0; }; }
        if (title === void 0) { title = 'Nahlásiť chybu'; }
        if (textAreaPlaceholder === void 0) { textAreaPlaceholder = 'Popíšte chybu...'; }
        if (fileUploadLabelText === void 0) { fileUploadLabelText = 'Vyberte súbory (max. 5)'; }
        if (submitButtonText === void 0) { submitButtonText = 'Odoslať'; }
        if (cancelButtonText === void 0) { cancelButtonText = 'Zrušiť'; }
        if (toastSuccessMessage === void 0) { toastSuccessMessage = 'Hlásenie bolo úspešne odoslané.'; }
        if (toastErrorMessage === void 0) { toastErrorMessage = 'Chyba pri odosielaní hlásenia.'; }
        // create dialog backdrop
        var backdrop = document.createElement("div");
        backdrop.classList.add("bug-report-backdrop");
        // Create a div element for the dialog
        var dialog = document.createElement("div");
        dialog.classList.add("bug-report-dialog");
        var titleElement = document.createElement("h2");
        titleElement.textContent = title;
        var select = document.createElement("select");
        select.classList.add("select");
        var options = ["Nahlásenie chyby", "Podnet na zlepšenie"];
        options.forEach(function (option) {
            var optionElement = document.createElement("option");
            optionElement.textContent = option;
            select.appendChild(optionElement);
        });
        // create input element for subject
        var subjectInput = document.createElement("input");
        subjectInput.placeholder = "Predmet";
        subjectInput.classList.add("bug-subject");
        // Create input element for bug description
        var descriptionInput = document.createElement("textarea");
        descriptionInput.placeholder = textAreaPlaceholder;
        descriptionInput.rows = 10;
        descriptionInput.classList.add("bug-description");
        // Create main container for file upload
        var fileUploadContainer = document.createElement("div");
        fileUploadContainer.classList.add("file-upload-container");
        // Create file upload element
        var fileUpload = document.createElement("input");
        fileUpload.type = "file";
        fileUpload.accept = "image/*,.pdf,.doc,.docx,.txt,.zip,.rar,.7z,.tar,.gz";
        fileUpload.id = "file-upload";
        fileUpload.classList.add("file-upload");
        fileUpload.multiple = true; // Allow multiple file selection
        // Create label for file upload
        var fileUploadLabel = document.createElement("label");
        fileUploadLabel.htmlFor = "file-upload";
        fileUploadLabel.textContent = fileUploadLabelText;
        fileUploadLabel.classList.add("file-upload-label");
        // Append file upload element and its label to the container
        fileUploadContainer.appendChild(fileUpload);
        fileUploadContainer.appendChild(fileUploadLabel);
        // Array to hold selected files
        var selectedFiles = [];
        // Add event listener for file upload
        fileUpload.addEventListener("change", function (e) {
            // Clear previous file items
            while (fileUploadContainer.firstChild !== fileUpload) {
                fileUploadContainer.removeChild(fileUploadContainer.lastChild);
            }
            // Get selected files
            var files = fileUpload.files;
            // Check count of files, max 5, if more, remove first files
            if (files.length > 5) {
                selectedFiles = Array.prototype.slice.call(files).slice(-5);
            }
            else {
                selectedFiles = Array.prototype.slice.call(files);
            }
            console.log(selectedFiles);
            // Create a file item for each file
            selectedFiles.forEach(function (file) {
                var fileItem = document.createElement("div");
                fileItem.textContent = file.name;
                fileItem.classList.add("file-item");
                fileUploadLabel.appendChild(fileItem);
                // Create remove button for each file
                var removeButton = document.createElement("button");
                removeButton.textContent = "x";
                removeButton.classList.add("remove-button");
                fileItem.appendChild(removeButton);
                // Add event listener to remove button
                removeButton.addEventListener("click", function (e) {
                    fileItem.remove();
                    // Remove file from selected files array
                    selectedFiles = selectedFiles.filter(function (f) { return f !== file; });
                    // When clicked, prevent from opening file dialog
                    e.preventDefault();
                    e.stopPropagation();
                });
            });
        });
        // Both buttons add to one div with class buttons and add to dialog
        // Create div for buttons
        var buttons = document.createElement("div");
        buttons.classList.add("buttons");
        // Create submit button
        var submitButton = document.createElement("button");
        submitButton.textContent = submitButtonText;
        submitButton.classList.add("submit-button");
        // Create cancel button
        var cancelButton = document.createElement("button");
        cancelButton.textContent = cancelButtonText;
        cancelButton.classList.add("cancel-button");
        buttons.appendChild(cancelButton);
        buttons.appendChild(submitButton);
        // Add event listener to submit button
        submitButton.addEventListener("click", function () {
            var bugDescription = descriptionInput.value;
            var bugType = select.value;
            var subject = subjectInput.value;
            if (bugDescription) {
                // Get the final selected files
                var files_1 = new DataTransfer();
                selectedFiles.forEach(function (file) { return files_1.items.add(file); });
                var reportData = generateReportData(appCode, loggedUser, bugType, bugDescription, subject);
                sendReport(files_1.files, reportData, onSuccess, onError, toastSuccessMessage, toastErrorMessage);
                // Close the dialog
                removeAll(dialog, backdrop);
            }
        });
        cancelButton.addEventListener("click", function () {
            onCancel();
            removeAll(dialog, backdrop);
        });
        // Append input and button to the dialog
        dialog.appendChild(titleElement);
        dialog.appendChild(select);
        dialog.appendChild(subjectInput);
        dialog.appendChild(descriptionInput);
        dialog.appendChild(fileUploadContainer);
        dialog.appendChild(buttons);
        // Check validity, the description must be filled, if not disable submit button
        descriptionInput.addEventListener("input", function () {
            submitButton.disabled = !descriptionInput.value;
        });
        // First check for description validity
        submitButton.disabled = !descriptionInput.value;
        document.body.appendChild(backdrop);
        // Append dialog to the body
        document.body.appendChild(dialog);
        // Load CSS file
        loadCssFile();
    }
    ReportBug.openDialog = openDialog;
    function sendReport(files, data, onSuccess, onError, successMessage, errorMessage) {
        if (onSuccess === void 0) { onSuccess = function () { return void 0; }; }
        if (onError === void 0) { onError = function () { return void 0; }; }
        if (successMessage === void 0) { successMessage = 'Hlásenie bolo úspešne odoslané.'; }
        if (errorMessage === void 0) { errorMessage = 'Chyba pri odosielaní hlásenia.'; }
        // create multipart form data
        var formData = new FormData();
        Object.keys(data).forEach(function (key) {
            formData.append("request.".concat(key), data[key]);
        });
        // Append files
        for (var i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }
        fetch('https://emailer-api-test.msweb.tuke.sk/api/help/create', {
            method: 'POST', // HTTP method
            body: formData // Body data type must match "Content-Type" header
        })
            .then(function (data) {
            onSuccess();
            createToastMessage(successMessage, 'success');
        }) // Handle the data from the response
            .catch(function (error) {
            console.error('Error:', error);
            onError(error);
            createToastMessage(errorMessage, 'error');
        }); // Handle any errors
    }
    ReportBug.sendReport = sendReport;
    function loadCssFile() {
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/assets/report-bug/report-bug.css';
        document.head.appendChild(link);
    }
    ReportBug.loadCssFile = loadCssFile;
    function removeCssFile() {
        var link = document.querySelector('link[href="/assets/report-bug/report-bug.css"]');
        link.remove();
    }
    ReportBug.removeCssFile = removeCssFile;
    function removeAll(dialog, backdrop) {
        dialog.remove();
        backdrop.remove();
        removeCssFile();
    }
    ReportBug.removeAll = removeAll;
    function generateTechData() {
        var techData = {
            userAgent: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
            cookiesEnabled: navigator.cookieEnabled,
            online: navigator.onLine,
            storage: {
                local: {
                    length: localStorage.length,
                    data: __assign({}, localStorage)
                },
                session: {
                    length: sessionStorage.length,
                    data: __assign({}, sessionStorage)
                }
            },
            screen: {
                width: screen.width,
                height: screen.height,
                availWidth: screen.availWidth,
                availHeight: screen.availHeight
            }
        };
        return techData;
    }
    ReportBug.generateTechData = generateTechData;
    function generateReportData(appCode, loggedUser, type, description, subject, techData) {
        if (techData === void 0) { techData = generateTechData(); }
        return {
            appCode: appCode,
            authorId: loggedUser.id,
            authorLogin: loggedUser.login,
            authorName: loggedUser.name,
            authorEmail: loggedUser.email,
            authorSurname: loggedUser.surname,
            subject: subject,
            category: type,
            body: description
        };
    }
    ReportBug.generateReportData = generateReportData;
    // create toast message
    function createToastMessage(message, type) {
        if (type === void 0) { type = 'success'; }
        loadCssFile();
        var toast = document.createElement('div');
        toast.classList.add('toast', type);
        toast.textContent = message;
        document.body.appendChild(toast);
        setTimeout(function () {
            toast.remove();
            removeCssFile();
        }, 5000);
    }
    ReportBug.createToastMessage = createToastMessage;
})(ReportBug || (ReportBug = {}));
